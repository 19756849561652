<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-card class="bg-transparent mb-6">
        <v-row class="pa-0 ma-0">
          <h2
            class="text-h3 mr-4 text-black font-weight-bolder mb-0 cursor-pointer"
          >
            {{ newQuest.title }}
          </h2>
        </v-row>
      </v-card>
      <v-card class="card-shadow pa-4 border-radius-l">
        <GmapMap
          :zoomControl="false"
          :mapTypeControl="false"
          :scaleControl="false"
          :streetViewControl="false"
          :rotateControl="false"
          fullscreenControl="false"
          :disableDefaultUI="false"
          :center="center"
          :zoom="zoom"
          ref="map"
          map-type-id="terrain"
          style="width: 500px; height: 300px"
          class="border-radius-l"
        >
          <GmapCircle
            v-for="(pin, index) in markers"
            :key="index"
            :center="pin.position"
            :radius="10000"
            :visible="true"
            :options="{
              fillColor: '#00B57D',
              fillOpacity: 0.5,
              strokeColor: '#00B57D',
            }"
          ></GmapCircle>
        </GmapMap>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import DatatableSearch from "./Widgets/DatatableSearch.vue";

export default {
  name: "datatables",
  components: {
    DatatableSearch,
  },
  computed: {
    newQuest() {
      return this.$store.getters.newQuest;
    },
  },
  data() {
    return {
      zoom: 5,
      center: { lat: 59.339025, lng: 18.065818 },
      markers: [
        {
          Id: 1,
          name: "Oslo",
          position: { lat: 59.923043, lng: 10.752839 },
          radius: 10000,
        },
        {
          Id: 2,
          name: "Stockholm",
          position: { lat: 59.339025, lng: 18.065818 },
          radius: 10000,
        },
        {
          Id: 3,
          name: "Copenhagen",
          position: { lat: 55.675507, lng: 12.574227 },
          radius: 10000,
        },
        {
          Id: 4,
          name: "Berlin",
          position: { lat: 52.521248, lng: 13.399038 },
          radius: 10000,
        },
        {
          Id: 5,
          name: "Paris",
          position: { lat: 48.856127, lng: 2.346525 },
          radius: 10000,
        },
      ],
    };
  },
};
</script>
